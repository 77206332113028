import { Link } from "react-router-dom";
import "./HomePage.css";
import config from "../../config.js";
import cirasLogo from "../../images/CIRAS-Logo_Final_WhiteFill_ver2-01.png";
import { useEffect, useState } from "react";
import { fetchFromAPI } from "../../utils/hooks.js";
import Loader from "../Loader.js";
import CountUp from "react-countup";
import { ReactComponent as ReadyToFishIcon } from "../../images/ReadyToFishIcon.svg";
import { ReactComponent as NumberOfFishRecordedIcon } from "../../images/NumberOfFishRecorededIcon.svg";
import { ReactComponent as NumberOfSurveysIcon } from "../../images/NumberOfSurveysIcon.svg";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Carousel from "react-material-ui-carousel";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import YouTube from "react-youtube";
import videoBg from "../../images/CIRAS-Loop.mp4";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";



function HomePage() {
  const [isLoading, setLoading] = useState(true);
  const [surveyInfo, setSurveyInfo] = useState(null);
  const [openVideo, setOpenVideo] = useState(false);

  useEffect(() => {
    fetchFromAPI("LandingPageData")
      .then((response) => {
        setSurveyInfo(response);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const ytOpts = {
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  var dialogRender = (
    <Dialog
      open={openVideo}
      onClose={() => setOpenVideo(false)}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Welcome to CIRAS</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setOpenVideo(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <YouTube videoId={config.YOUTUBE_VIDEO_ID} opts={ytOpts} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );

  var surveyInfoRender = isLoading ? (
    <Loader />
  ) : (
    surveyInfo.success && (
      <div className="survey-info">
        <Stack spacing={3} useFlexGap>
          <Typography
            component="h2"
            variant="h2"
            sx={{
              fontSize: "3rem",
              paddingTop: "2rem",
              color: () => "#045070",
            }}
          >
            Trending
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            sx={{
              fontSize: "2rem",
              fontWeight: "bold",
              paddingTop: "2rem",
              color: () => "#045070",
            }}
          >
            FISH SPECIES
          </Typography>
          <Carousel
            animation="fade"
            autoPlay={true}
            navButtonsProps={{
              style: {
                backgroundColor: "transparent",
              },
            }}
            NextIcon={
              <ArrowRightIcon
                sx={{
                  fontSize: "4rem",
                  color: () => "#045070",
                }}
              />
            }
            PrevIcon={
              <ArrowLeftIcon
                sx={{
                  fontSize: "4rem",
                  color: () => "#045070",
                }}
              />
            }
          >
            {surveyInfo.result.TrendingSpecies?.map((item, i) => (
              <div className="trending-species-fish" key={i}>
                <img
                  alt={item.name}
                  src={`data:image/png;base64,${item.value}`}
                />
                <Typography
                  component="span"
                  variant="h3"
                  sx={{
                    fontSize: "1.5rem",
                  }}
                >
                  {item.name}
                </Typography>
              </div>
            ))}
          </Carousel>
          <Divider />
          <Typography
            component="h3"
            variant="h3"
            sx={{
              fontSize: "2rem",
              fontWeight: "bold",
              color: () => "#045070",
            }}
          >
            WATERS
          </Typography>
          <Carousel
            animation="fade"
            autoPlay={true}
            navButtonsProps={{
              style: {
                backgroundColor: "transparent",
              },
            }}
            NextIcon={
              <ArrowRightIcon
                sx={{
                  fontSize: "4rem",
                  color: () => "#045070",
                }}
              />
            }
            PrevIcon={
              <ArrowLeftIcon
                sx={{
                  fontSize: "4rem",
                  color: () => "#045070",
                }}
              />
            }
          >
            {surveyInfo.result.TrendingWaters?.map((item, i) => (
              <div className="trending-species-fish" key={i}>
                <Typography
                  component="span"
                  variant="h3"
                  sx={{
                    fontSize: "1.5rem",
                  }}
                >
                  {item.name}
                </Typography>
                <br />
                {item.LatLong}
                <br />
                County:
                <br />
                {item.Counties}
              </div>
            ))}
          </Carousel>
          <Divider
            sx={{
              borderBottomWidth: 5,
              borderColor: "#045070",
            }}
          />
          <Typography
            component="h2"
            variant="h2"
            sx={{
              fontSize: "3rem",
              paddingTop: "1rem",
              color: () => "#045070",
            }}
          >
            By the Numbers
            <Typography
              component="span"
              variant="h2"
              sx={{
                fontSize: "1.2rem",
                display: "block",
              }}
            >
              past 30 days
            </Typography>
          </Typography>
          <div className="survey-info">
            <CountUp
              start={0}
              end={surveyInfo.result?.SurveysSubmitted[0].value}
              enableScrollSpy
            >
              {({ countUpRef }) => (
                <Typography
                  component="span"
                  variant="h3"
                  sx={{
                    fontSize: "5rem",
                    color: () => "#045070",
                  }}
                >
                  <span ref={countUpRef} />
                </Typography>
              )}
            </CountUp>
            <NumberOfSurveysIcon />
          </div>
          <Typography
            component="span"
            variant="h3"
            sx={{
              fontSize: "1.5rem",
            }}
          >
            Number of Surveys Submitted
          </Typography>
          <div className="survey-info">
            <CountUp
              start={0}
              end={surveyInfo.result?.FishReported[0].value}
              enableScrollSpy
            >
              {({ countUpRef }) => (
                <Typography
                  component="span"
                  variant="h3"
                  sx={{
                    fontSize: "5rem",
                    color: () => "#045070",
                  }}
                >
                  <span ref={countUpRef} />
                </Typography>
              )}
            </CountUp>
            <NumberOfFishRecordedIcon />
          </div>
          <Typography
            component="span"
            variant="h3"
            sx={{
              fontSize: "1.5rem",
              paddingBottom: "4rem",
            }}
          >
            Number of Fish Recorded
          </Typography>
        </Stack>
      </div>
    )
  );

  return (
    <>
      <div className="banner-main">
        <div className="banner-overlay"></div>
        <video src={videoBg} autoPlay controls loop muted playsInline  aria-label="Background video of an Angler fishing from the shore"/>
        <div className="banner-content">
          <Stack spacing={2} alignItems={"center"}>
            <img
              src={cirasLogo}
              alt="California Inland Recreational Angler Survey"
            />
            <Button
              variant="contained"
              onClick={() => {
                setOpenVideo(true);
              }}
              sx={{
                backgroundColor: () => "#045070",
              }}
            >
              Watch This Video
            </Button>            
          </Stack>
        </div>
      </div>

      <div className="section collapsed home">
        <div className="container">
          <div className="page-title">
            <Stack spacing={2} useFlexGap>
              <Typography
                component="h1"
                variant="h1"
                sx={{
                  fontSize: "4rem",
                  paddingTop: "2rem",
                  fontWeight: "bold",
                  color: () => "#045070",
                }}
              >
                CIRAS
              </Typography>

              

              <Typography
                textAlign="center"
                sx={{
                  fontSize: "2rem",
                  fontStyle: "italic",
                  alignSelf: "center",
                  lineHeight: 1.2,
                  width: { sm: "100%", md: "80%" },
                }}
              >
                An improved angler experience powered by angling data.
              </Typography>
            </Stack>
            <Link
              className="btn ciras-nav btn-lg btn-primary rounded-15"
              to="survey"
            >
              <span
                className="ca-gov-icon-online-services"
                aria-hidden="true"
              ></span>{" "}
              Enter Survey Data
            </Link>

            <Link
              className="btn ciras-nav btn-lg btn-primary rounded-15"
              to="dashboard"
            >
              <SvgIcon
                className="ready-to-fish-svg"
                component={ReadyToFishIcon}
                inheritViewBox
              />{" "}
              Ready to Fish?
            </Link>
          </div>
          {surveyInfoRender}
          {dialogRender}
        </div>
      </div>
    </>
  );
}

export default HomePage;
